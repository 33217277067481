import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import marker1 from "../images/projects/marker-test3.png";
import marker2 from "../images/projects/marker-test4.png";
import mbdb from "../images/projects/mbdb.io.png";
import mbdbcms from "../images/projects/mbdbcms.png";
import pinesweeper from "../images/projects/pinesweeper.png";
import kitchen from "../images/projects/thekitchen.png";
import match from "../images/projects/match.png";
import blackjack from "../images/projects/blackjack.png";

import "../css/style.css";

function ProjectsPage() {
    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title="Projects"
            />
            <section className="flex flex-col">
                <hr className="mx-20 mt-20 mb-0 border-blue-700" />
                <p className="flex justify-center align-center mb-4 text-sm text-gray-600 font-bold italic">
                    In Progress
                </p>
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        // style={{ backgroundImage: `url(${pinesweeper})` }}
                        title="Woman holding a mug"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #cli
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #devops
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #node
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                Juuj Server Tool
                            </div>
                            <p className="text-gray-900 text-base">
                                A node based command line interface tool that
                                assists and automates server creation via a menu
                                based prompt.
                            </p>
                        </div>
                        <div className="flex">
                            <button className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 hidden">
                                Play
                            </button>
                            <a
                                href="https://github.com/clementsjj/server-tool-cli"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                        <ol className="text-sm text-gray-600">
                            Future Improvements
                            <li className="ml-4 text-sm text-gray-600">
                                - Finish
                            </li>
                            <li className="ml-4 text-sm text-gray-600">
                                - Publish to NPM
                            </li>
                            <li className="ml-4 text-sm text-gray-600">
                                - Continue Versioning
                            </li>
                        </ol>
                    </div>
                </div>
                {/* ~~~~~~~~~~~~~~~~~~ Card 1.2 ~~~~~~~~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        // style={{ backgroundImage: `url(${pinesweeper})` }}
                        title="Woman holding a mug"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #servers
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #devops
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #linux
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                Server Collection
                            </div>
                            <p className="text-gray-900 text-base">
                                Assortment of server settings named after Tsars
                                and other Russians
                            </p>
                        </div>
                        <div className="flex">
                            <a
                                href="https://github.com/clementsjj/servers"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                            <button className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 hidden">
                                Github
                            </button>
                        </div>
                        <ol className="text-sm text-gray-600">
                            Future Improvements
                            <li className="ml-4 text-sm text-gray-600">
                                - Ongoing
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            {/*  ###################### SECTION 2 #################### */}
            <section className="flex flex-col">
                <hr className="mx-20 mt-20 mb-0 border-blue-700" />
                <p className="flex justify-center align-center mb-4 text-sm text-gray-600 font-bold italic">
                    [Mostly] Complete
                </p>

                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        style={{ backgroundImage: `url(${pinesweeper})` }}
                        title="Woman holding a mug"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #vanillaJS
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #javascript
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #win95
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                Pinesweeper
                            </div>
                            <p className="text-gray-900 text-base">
                                A retro homage to a classic. Based on
                                Minesweeper in a Windows 95 setting, Pinesweeper
                                uses vanilla JavaScript and Object Oriented
                                Programming.
                            </p>
                        </div>

                        <div className="flex">
                            <a
                                href="http://pinesweeper.s3-website-us-east-1.amazonaws.com/"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Play
                            </a>
                            <a
                                href="https://github.com/clementsjj/games/tree/master/pinesweeper"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                        <ol className="text-sm text-gray-600">
                            Future Improvements
                            <li className="ml-4 text-sm text-gray-600">
                                - Difficulty Level
                            </li>
                            <li className="ml-4 text-sm text-gray-600">
                                - Scoring System
                            </li>
                            <li className="ml-4 text-sm text-gray-600">
                                - Database for scores using sqlite
                            </li>
                        </ol>
                    </div>
                </div>
                {/* ~~~~~~~~~~~ CARD 2 ~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        style={{ backgroundImage: `url(${mbdb})` }}
                        title="mbdb.io"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #react
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #googleMaps
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #manhattan
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                Manhattand Bathroom Database
                                <p className="text-lg">MBDB</p>
                            </div>
                            <p className="text-gray-900 text-base">
                                A database of user generated bathroom codes
                            </p>
                        </div>
                        <div className="flex justify-around">
                            <img className="smallIcon" src={marker1} />
                            <img className="smallIcon" src={marker2} />
                            <img className="smallIcon" src={marker1} />
                            <img className="smallIcon" src={marker2} />
                        </div>
                        <div className="flex">
                            <button className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 hidden">
                                Play
                            </button>
                            <a
                                href="https://github.com/clementsjj/mbdb-front-stable"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                        <ol className="text-sm text-gray-600">
                            Future Improvements
                            <li className="ml-4 text-sm text-gray-600">
                                - Needs to be polished, re-hosted permanently,
                                and beta tested
                            </li>
                            <li className="ml-4 text-sm text-gray-600">
                                - Actually use and promote
                            </li>
                            <li className="ml-4 text-sm text-gray-600">
                                - Make DC version
                            </li>
                        </ol>
                    </div>
                </div>
                {/* ~~~~~~~~~~~ CARD 3 ~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        style={{ backgroundImage: `url(${mbdbcms})` }}
                        title="mbdb cms"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #cms
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #googleMaps
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #manhattan
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                Manhattan Bathroom Database Content Management
                                System
                                <p className="text-lg">MBDB CMS</p>
                            </div>
                            <p className="text-gray-900 text-base">
                                A content management system for MBDB
                            </p>
                        </div>
                        <div className="flex">
                            <button className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 hidden">
                                Play
                            </button>
                            <a
                                href="https://github.com/clementsjj/mbdb-cms"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-sm text-gray-600">
                                Future Imporvements
                            </p>
                            <p className="ml-4 text-sm text-gray-600">
                                - Needs to be hosted again
                            </p>
                        </div>
                    </div>
                </div>
                {/* ~~~~~~~~~~~ CARD 4 ~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        style={{ backgroundImage: `url(${kitchen})` }}
                        title="the kitchen app"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #spa
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #react
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #recipes
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                The Kitchen
                            </div>
                            <p className="text-gray-900 text-base">
                                A recipe finding app
                            </p>
                        </div>
                        <div className="flex">
                            <button className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 hidden">
                                Play
                            </button>
                            <a
                                href="https://github.com/clementsjj/Kitchen-Final"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-sm text-gray-600">
                                Future Imporvements
                            </p>
                            <p className="ml-4 text-sm text-gray-600">
                                - Needs to be hosted again
                            </p>
                        </div>
                    </div>
                </div>
                {/* ~~~~~~~~~~~ CARD 5 - Match Game ~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        style={{ backgroundImage: `url(${match})` }}
                        title="the match app"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #vanillaJS
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #OOP
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #javascipt
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                Match Game
                            </div>
                            <p className="text-gray-900 text-base">
                                A kind of fun matching game built in vanilla
                                javascript. It keeps time and assigns a score
                                based on how well you did.
                            </p>
                        </div>
                        <div className="flex">
                            <a
                                href="http://juuj-matching-game.s3-website-us-east-1.amazonaws.com/"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Play
                            </a>
                            <a
                                href="https://github.com/clementsjj/games/tree/master/matching-game"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                        <ol className="text-sm text-gray-600">
                            Future Improvements
                            <li className="ml-4 text-sm text-gray-600">
                                - Need to fix database - sqlite3
                            </li>
                        </ol>
                    </div>
                </div>
                {/* ~~~~~~~~~~~ CARD 5 - Blackjack ~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        style={{ backgroundImage: `url(${blackjack})` }}
                        title="the match app"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #vanillaJS
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #OOP
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #javascipt
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                Blackjack
                            </div>
                            <p className="text-gray-900 text-base">
                                A simple blackjack game
                            </p>
                        </div>
                        <div className="flex">
                            <a
                                href="http://juuj-blackjack.s3-website-us-east-1.amazonaws.com/"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Play
                            </a>
                            <a
                                href="https://github.com/clementsjj/games/tree/master/blackjack"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                        <ol className="text-sm text-gray-600">
                            Future Improvements
                            <li className="ml-4 text-sm text-gray-600">
                                - Needs a little polishing
                            </li>
                        </ol>
                    </div>
                </div>
                {/* ~~~~~~~~~~~ CARD 6 clementsjj.com ~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        // style={{ backgroundImage: `url(${match})` }}
                        title="the match app"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #gatsbyjs
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #tailwind
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #react
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                clementsjj.com
                            </div>
                            <p className="text-gray-900 text-base">
                                This website! It uses GatsbyJS, and mostly
                                tailwindCSS. The older version, also using react
                                via GatsbyJS is{" "}
                                <a
                                    className="text-blue-500"
                                    href="https://github.com/clementsjj/clementsjj.github.io">
                                    here
                                </a>{" "}
                                for cataloging purposes.
                            </p>
                        </div>
                        <div className="flex">
                            <button className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 hidden">
                                Play
                            </button>
                            <a
                                href="https://github.com/clementsjj/clementsjj.com"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                    </div>
                </div>
                {/* ~~~~~~~~~~~ CARD 7 - tsarchild ~~~~~~~~~~~~~~ */}
                <div className="my-4 max-w-sm w-full lg:max-w-full lg:flex shadow-2xl">
                    <div
                        className="h-48 lg:h-auto lg:w-48 flex-none bg-contain overflow-hidden bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center "
                        // style={{ backgroundImage: `url(${match})` }}
                        title="the match app"></div>
                    <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-8">
                            <div className="flex justify-start">
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #ghost
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #linux
                                </span>
                                <span className="text-sm text-gray-600 flex items-center mx-4">
                                    #handlebars
                                </span>
                            </div>
                            <div className="text-gray-900 font-bold text-xl mb-2">
                                tsarchild.com
                            </div>
                            <p className="text-gray-900 text-base">
                                A ghost blog project. Ghost is pretty cool. And
                                fun to maintain a blog server to write things
                                down. Sometimes I do other things with the
                                server, like break it. Very very much a work in progress.
                            </p>
                        </div>
                        <div className="flex">
                            <a
                                href="https://tsarchild.com"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 "
                                target="_blank"
                                rel="noopener noreferrer">
                                Go
                            </a>
                            <a
                                href="https://github.com/clementsjj/clementsjj.com"
                                className="bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded mx-2 hidden"
                                target="_blank"
                                rel="noopener noreferrer">
                                Github
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flex flex-col">
                <hr className="mx-20 mt-20 mb-0 border-blue-700" />
                <p className="flex justify-center align-center mb-4 text-sm text-gray-600 font-bold italic">
                    Future
                </p>
                <ol>
                    <li>- Landing page for Yacht project</li>
                    <li>- Open to Ideas!</li>
                </ol>
            </section>
        </Layout>
    );
}

export default ProjectsPage;
